import React, { useState, useEffect } from 'react';
import NavBar from 'components/NavBar/NavBar';
import Footer from 'components/Footer/Footer';
import Terms from 'components/Terms/Terms';
import { FaAngleUp } from 'react-icons/fa';
import ReactLoading from 'react-loading';
import 'assets/css/terms.scss';
import StringObject from 'interfaces/stringObject';
import AnimatedCircle from 'components/AnimatedCircle/AnimatedCircle';

const Privacy = () => {
  const [termsData, setTermsData] = useState<StringObject>();
  const pageId = 'terms';
  const pageName = 'Privacy Policy';

  useEffect(() => {
    const getTerms = async () => {
      const uri =
        process.env.NODE_ENV === 'development'
          ? 'http://localhost:9000'
          : 'https://api.gameout.app';

      try {
        const res = await fetch(`${uri}/user/v1/privacy`);
        if (res) {
          const { data } = await res.json();
          if (data) setTermsData(data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getTerms();
  }, []);

  return (
    <div>
      <NavBar pageId={'#' + pageId} pageName={pageName} />

      <section id={pageId} className="section bg">
        <AnimatedCircle />

        {!termsData && (
          <div className="spinner">
            <ReactLoading type="bars" color="#fff" />
          </div>
        )}

        <div id="wrapper">
          <div className="mt-5">{termsData && <Terms data={termsData} />}</div>
        </div>
      </section>

      <Footer />

      {/*tap-top*/}
      <div className="tap-top">
        <div>
          <FaAngleUp aria-hidden="true" />
        </div>
      </div>
    </div>
  );
};

export default Privacy;
