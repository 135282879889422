import i18n from 'i18next';
import english from './en/en.json';
import spanish from './en/es.json';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: english,
  },
  es: {
    translation: spanish,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',
  resources,
});

export default i18n;
