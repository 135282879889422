import React from 'react';
import { Link } from 'react-router-dom';
// import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
// import classes from './Footer.module.scss';

export default function Footer() {
  return (
    <div>
      <div id="fb-root"></div>
      <footer className="copy-right-bg">
        <div className="container">
          <div className="row">
            {/* <div className="col-md-12 text-center">
                            <div className={classes['social-footer']}">
                                <ul>
                                    <li><a href="/"> <FaFacebookF aria-hidden="true" /></a></li>
                                    <li><a href="/"> <FaTwitter aria-hidden="true" /></a></li>
                                    <li><a href="/"> <FaInstagram aria-hidden="true" /></a></li>
                                </ul>
                            </div>
                        </div> */}
            <div className="col-md-12 text-center mt-3 mb-3">
              <p className="mt-3">
                <Link to="/terms">Terms of Service</Link> |{' '}
                <Link to="/privacy">Privacy Policy</Link> |{' '}
                <Link to="/guidelines">Community Guidelines</Link>
              </p>
              {/* <p className={classes.copyright}>Copyright &#169; 2021 Gameout. All rights reserved.</p> */}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
