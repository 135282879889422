export const getLocale = () => {
  let locales: string[] = [];

  if (typeof window !== 'undefined') {
    const { navigator }: { navigator: any } = window;

    if (navigator.languages) {
      locales = navigator.languages;
    } else if (navigator.language) {
      locales.push(navigator.language);
    } else if (navigator.userLanguage) {
      locales.push(navigator.userLanguage);
    } else if (navigator.browserLanguage) {
      locales.push(navigator.browserLanguage);
    } else if (navigator.systemLanguage) {
      locales.push(navigator.systemLanguage);
    }
  }

  let firstLocale = 'en'; // Fallback
  for (const locale of locales) {
    if (!locale) continue;

    if (locale.indexOf('-') === -1) {
      firstLocale = locale.toLowerCase();
      break;
    }

    firstLocale = locale.split(/-|_/)[0].toLowerCase();
    break;
  }

  return firstLocale;
};
