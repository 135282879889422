import NavBar from 'components/NavBar/NavBar';
import Footer from 'components/Footer/Footer';
import AnimatedCircle from 'components/AnimatedCircle/AnimatedCircle';
import { FaAngleUp } from 'react-icons/fa';
import 'assets/css/account-deletion.scss';

const AccountDeletion = () => {
  const pageId = 'account-deletion';
  const pageName = 'Delete Account';

  return (
    <div>
      <NavBar pageId={'#' + pageId} pageName={pageName} />

      <section id={pageId} className="section bg">
        <AnimatedCircle />

        <div id="wrapper">
          <div className="mt-5">
            <h1 className="text-center">GAMEOUT ACCOUNT DELETION</h1>
            <h4 className="mt-5">How do I permanently delete my account?</h4>
            <p>
              If you've made up your mind and you wish to permanently delete your account, you can
              do so by taking the following steps on the mobile app:
            </p>
            <p>{'Settings -> Delete Account'}</p>
            <p>You'll be asked to enter your password to complete the deletion.</p>
          </div>
        </div>
      </section>

      <Footer />

      {/*tap-top*/}
      <div className="tap-top">
        <div>
          <FaAngleUp aria-hidden="true" />
        </div>
      </div>
    </div>
  );
};

export default AccountDeletion;
