window.onload = function () {
  /* Mobile menu nav click hide collapse */
  const mobile_menu = window.innerWidth;
  if (mobile_menu < 991) {
    let links = document.querySelectorAll('nav a.nav-link');
    for (let link of links) {
      link.addEventListener('click', function (event) {
        let element = event.target;
        if (!element.classList.contains('dropdown-toggle')) {
          let navbar = document.querySelector('.navbar-collapse');
          navbar.classList.remove('show').add('hide');
        }
      });
    }
  }

  const dropdown = document.getElementsByClassName('dropdown-btn');
  for (let i = 0; i < dropdown.length; i++) {
    dropdown[i].addEventListener('click', function () {
      this.classList.toggle('active');
      var dropdownContent = this.nextElementSibling;
      if (dropdownContent.style.display === 'block') {
        dropdownContent.style.display = 'none';
      } else {
        dropdownContent.style.display = 'block';
      }
    });
  }

  const tapToTopElement = document.querySelector('.tap-top');
  window.addEventListener('scroll', function () {
    /* Darkheader */
    const scroll =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const navbar = document.querySelector('.navbar');
    if (navbar) {
      if (scroll >= 60) {
        navbar.classList.add('darkHeader');
      } else {
        navbar.classList.remove('darkHeader');
      }
    }

    /* Tap to top: display */
    if (scroll > 600) {
      fadeIn(tapToTopElement);
    } else {
      fadeOut(tapToTopElement);
    }
  });

  /* Tap to top: click */
  if (tapToTopElement) {
    tapToTopElement.addEventListener('click', function (event) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      return false;
    });
  }

  const navLinks = document.querySelectorAll('nav a');
  for (let link of navLinks) {
    link.addEventListener('click', function (event) {
      if (this.hash !== '') {
        event.preventDefault();
        const section = document.querySelector(this.hash);
        section.getBoundingClientRect();
        window.scroll({ top: section.offsetTop, left: 0, behavior: 'smooth' });
        return false;
      }
    });
  }
};

function fadeOut(el) {
  if (!el) return;
  if (el.style.display === 'none') return;

  el.style.opacity = 1;
  (function fade() {
    if ((el.style.opacity -= 0.1) < 0) {
      el.style.display = 'none';
    } else {
      requestAnimationFrame(fade);
    }
  })();
}

function fadeIn(el, display) {
  if (!el) return;
  if (el.style.display === 'block') return;

  el.style.opacity = 0;
  el.style.display = display || 'block';
  (function fade() {
    var val = parseFloat(el.style.opacity);
    if (!((val += 0.1) >= 1)) {
      el.style.opacity = val;
      requestAnimationFrame(fade);
    }
  })();
}
