import React from 'react';
import NavBar from 'components/NavBar/NavBar';
import classes from './Developer.module.scss';

import topMobilePng from 'assets/images/top-mobile.png';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import AnimatedCircle from 'components/AnimatedCircle/AnimatedCircle';

const Developer = () => {
  const { t } = useTranslation();

  const pageId = 'developer';
  const pageName = t('PAGES.DEVELOPERS');

  const navigateToDevelopers = () => {
    window.location.replace('https://developer.gameout.app/signup');
  };

  return (
    <div>
      <NavBar pageId={'#' + pageId} pageName={pageName} />

      <section id={pageId} className={`section slide-bg ${classes['slide-bg']}`}>
        <AnimatedCircle />
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex-1">
                <div className={`slide-text z-index-1000 ${classes['slide-text']}`}>
                  <div>
                    <h1>Increase your game engagement</h1>
                    <h4>
                      Gameout extends your game to mobile platforms, keeping players engaged and
                      reachable at all times.
                    </h4>
                    <Button
                      onClick={navigateToDevelopers}
                      loading={false}
                      globalClasses="mt-5"
                      className="primary">
                      Sign Up Now
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className={`col-md-6 home-right mobile-slid ${classes['mobile-slid']}`}>
              <img src={topMobilePng} alt="Mobile Phone" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Developer;
