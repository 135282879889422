import React from 'react';

import classes from './PhoneAnimated.module.scss';
// import './Landing.scss';

// Images
import topMobilePng from 'assets/images/top-mobile.png';
import top1Png from 'assets/images/top1.png';
import top2Png from 'assets/images/top2.png';
import top3Png from 'assets/images/top3.png';
import top4Png from 'assets/images/top4.png';
import top5Png from 'assets/images/top5.png';

export default function PhoneAnimated() {
  return (
    <div className="col-md-6">
      <div className={`home-right ${classes['home-right']}`}>
        <div className="phone-wrapper position-relative">
          <div className={`mobile-slid ${classes['mobile-slid']}`}>
            <img src={topMobilePng} alt="top1" className="img-fluid" />
          </div>
          <div className={`profile-1 ${classes['profile-1']}`}>
            <img src={top1Png} alt="top1" className="img-fluid" />
          </div>
          <div className={classes.emoji}>
            <img src={top5Png} alt="top5" className="img-fluid" />
          </div>
          <div className={`profile-2 ${classes['profile-2']}`}>
            <img src={top4Png} alt="top4" className="img-fluid" />
          </div>
          <div className={classes['player-message']}>
            <div
              id={classes['player-message-image']}
              style={{ background: 'url(' + top2Png + ')' }}>
              I love this game!
            </div>
          </div>
          <div className={classes['my-message']}>
            <div id={classes['my-message-image']} style={{ background: 'url(' + top3Png + ')' }}>
              Yeah, it's pretty cool
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
