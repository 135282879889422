import React from 'react';
import 'assets/css/work.scss';

// Images
import whiteLinePng from 'assets/images/title-line.png';
import icon1Png from 'assets/images/icon/1.png';
import icon2Png from 'assets/images/icon/2.png';
import icon3Png from 'assets/images/icon/3.png';

class Work extends React.Component {
  render() {
    return (
      <section className="section work" id="work">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 text-center">
              <div className="section-title">
                <h2>How Gameout Works</h2>
                <img src={whiteLinePng} alt="white-line" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 text-center">
              <div className="process-box position-relative">
                <img src={icon1Png} alt="" />
                <h3>Get the app</h3>
                <p>Get the app from either the App Store or the Play Store.</p>
                <img src={icon1Png} alt="" className="bg-icon" />
              </div>
            </div>
            <div className="col-lg-4 text-center">
              <div className="process-box position-relative">
                <img src={icon2Png} alt="" />
                <h3>Get a code</h3>
                <p>Generate a secret code for your account on your game's website.</p>
                <img src={icon2Png} alt="" className="bg-icon" />
              </div>
            </div>
            <div className="col-lg-4 text-center">
              <div className="process-box position-relative">
                <img src={icon3Png} alt="" />
                <h3>Enjoy</h3>
                <p>Enter the code in the app to link your game account.</p>
                <img src={icon3Png} alt="" className="bg-icon" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Work;
