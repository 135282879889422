import { FC } from 'react';
import classes from './AnimatedCircle.module.scss';
import classNames from 'classnames';

interface Props {
  top?: boolean;
}

const AnimatedCircle: FC<Props> = ({ top }) => {
  const circleClasses = classNames(classes['animated-circle'], { [classes['animated-circle--top']]: top });

  return (
    <div className={circleClasses}>
      <i></i>
      <i></i>
      <i></i>
    </div>
  );
};

export default AnimatedCircle;
