import React from 'react';
import StringObject from 'interfaces/stringObject';

export default function Terms({ data }: { data: StringObject }) {
  let listItems: StringObject = {};

  const isListItem = (key: string) => key.indexOf('__') !== -1;

  const getClass = (key: string): string => (['h1_1', 'p_1'].includes(key) ? 'text-center' : '');

  const getElementType = (key: string): string => {
    const first2Chars = key.substr(0, 2);
    if (first2Chars === 'p_') return 'p';
    return first2Chars;
  };

  const renderList = (listItems: StringObject) => {
    if (Object.entries(listItems).length > 0) {
      return (
        <ul className="terms-list">
          {Object.keys(listItems).map((key) => (
            <li key={key}>{listItems[key]}</li>
          ))}
        </ul>
      );
    }
    return null;
  };

  const formattedElements = Object.entries(data).reduce(
    (elements: React.ReactElement[], [key, value], idx, array): React.ReactElement[] => {
      if (isListItem(key)) {
        listItems = { ...listItems, ...{ [key]: value } };

        if (idx === array.length - 1)
          elements.push(<React.Fragment key={key}>{renderList(listItems)}</React.Fragment>);
      } else {
        const ElementType: any = getElementType(key);

        if (Object.entries(listItems).length > 0) {
          elements.push(
            <React.Fragment key={key}>
              {renderList(listItems)}
              <ElementType className={getClass(key)}>{value}</ElementType>
            </React.Fragment>
          );
          listItems = {};
        } else {
          elements.push(
            <ElementType key={key} className={getClass(key)}>
              {value}
            </ElementType>
          );
        }
      }
      return elements;
    },
    []
  );

  return <>{formattedElements}</>;
}
