import React from 'react';
// import './Features.scss';
import AnimatedCircle from 'components/AnimatedCircle/AnimatedCircle';
import classes from './Features.module.scss';

// Images
import whiteLinePng from 'assets/images/white-line.png';
import featureMobPng from 'assets/images/feature-mob.png';

export default function Features() {
  return (
    <section id="features" className={`section theme-bg feature ${classes.feature}`}>
      <div className="container">
        <AnimatedCircle />
        <div className={`row ${classes.row}`}>
          <div className="col-md-12 text-center">
            <div className="section-title">
              <h2 className="text-white">Gameout Features</h2>
              <img src={whiteLinePng} alt="white-line" className="img-fluid" />
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className={`feature-box ${classes['feature-box']}`}>
              <div className={`feature-timeline ${classes['feature-timeline']}`}>
                <ul>
                  <li className={`timeline ${classes.timeline}`}>
                    <h4 className="sub-title">Easy Setup</h4>
                    <p>Link your account in 2 simple steps</p>
                  </li>
                  <li className={`timeline ${classes.timeline}`}>
                    <h4>Multi Platform</h4>
                    <p>Available on Android and iOS</p>
                  </li>
                  <li className={`timeline ${classes.timeline}`}>
                    <h4 className="sub-title">Free to use</h4>
                    <p>It's free, no initial or monthly fees required</p>
                  </li>
                  <li className={`timeline ${classes.timeline}`}>
                    <h4 className="sub-title">Notifications</h4>
                    <p>Receive important game notifications</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={`col-md-4 feature-mobile z-index-1000 ${classes['feature-mobile']}`}>
            <img src={featureMobPng} alt="feature-mob" className="img-fluid" />
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className={`feature-box ${classes['feature-box']}`}>
              <div className={`feature-timeline-right ${classes['feature-timeline-right']}`}>
                <ul className="text-left">
                  <li className={`timeline-right ${classes['timeline-right']}`}>
                    <h4 className="sub-title">Messaging</h4>
                    <p>Send and receive messages from other players</p>
                  </li>
                  <li className={`timeline-right ${classes['timeline-right']}`}>
                    <h4>Newsfeed</h4>
                    <p>View and react to game and players content</p>
                  </li>
                  <li className={`timeline-right ${classes['timeline-right']}`}>
                    <h4>Character Based</h4>
                    <p>Perform actions with your in-game character</p>
                  </li>
                  <li className={`timeline-right ${classes['timeline-right']}`}>
                    <h4 className="sub-title">Secure</h4>
                    <p>Encrypted messages are self destroyed after 7 days</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
