import React from 'react';
import AnimatedCircle from 'components/AnimatedCircle/AnimatedCircle';
import classes from './NotFound.module.scss';

const NotFound = () => {
  return (
    <section className="section p-0">
      <div className={`${classes['error-main']} ${classes['not-found']}`}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="text-center">
                <h2 className={classes['error-font']}>
                  4<span>0</span>4
                </h2>
                <h2 className="f-bold">Oops! Error 404 not found.</h2>
                <div className="col-lg-8 offset-lg-2 col-12">
                  <h4>
                    The page you were looking for doesn't exist. We think the page may have moved.{' '}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AnimatedCircle top />
      </div>
    </section>
  );
};

export default NotFound;
