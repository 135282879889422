import { FC } from 'react';
import { LanguageSelectorModal as LangModal } from '@marsenit/gameout-ui';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { langActions } from 'store/lang';

interface Props {
  onClose: () => void;
}

const LanguageSelectorModal: FC<Props> = ({ onClose = () => null }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const onLanguageSelect = (code?: string) => {
    onClose();

    if (code) {
      i18n.changeLanguage(code);
      dispatch(langActions.setLang(code));
    }
  }

  return <LangModal onSelect={onLanguageSelect} />
}

export default LanguageSelectorModal;
