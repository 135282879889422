import React from 'react';
import 'assets/css/download.scss';
import classes from './GetNow.module.scss';

// Images
import logoPng from 'assets/images/logo.png';
import app1Png from 'assets/images/app1.png';
import app2Png from 'assets/images/app2.png';

export default function GetNow() {
  return (
    <section className="section download-bg">
      <div className="container">
        <div className="row">
          <div className="col-xl-3 display-flex">
            <div className="footer-logo">
              <img src={logoPng} alt="logo" className={classes.logo} />
            </div>
          </div>
          <div className="col-xl-5 display-flex">
            <div className="download-text">
              <h3>Available now for Android and iOS</h3>
            </div>
          </div>
          <div className="col-xl-4 display-flex">
            <div className="download-img">
              <ul>
                <li>
                  <a href="https://play.google.com/store/apps/details?id=gameout.app">
                    <img src={app1Png} alt="Play Store" className="img-fluid" />
                  </a>
                </li>
                <li>
                  <a href="https://apps.apple.com/us/app/gameout-your-game-connection/id1567877472">
                    <img src={app2Png} alt="App Store" className="img-fluid" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
