import React, { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getLocale } from 'utils/user-locale';
import { useDispatch } from 'react-redux';
import { langActions } from './store/lang';
import { useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from 'pages/Landing/Landing';
import TermsOfService from 'pages/TermsOfService/TermsOfService';
import Privacy from 'pages/Privacy/Privacy';
import Guidelines from 'pages/Guidelines/Guidelines';
import AccountDeletion from 'pages/AccountDeletion/AccountDeletion';
import NotFound from 'pages/NotFound/NotFound';
import Developer from 'pages/Developer/Developer';

const App = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const setLang = useCallback(() => {
    const lang = localStorage.getItem('lang') || getLocale();
    dispatch(langActions.setLang(lang));
    i18n.changeLanguage(lang);
  }, [dispatch, i18n]);

  useEffect(() => {
    setLang();
  }, [setLang]);

  return (
    <Suspense fallback={null}>
      <Router basename={'/'}>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="terms" element={<TermsOfService />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="guidelines" element={<Guidelines />} />
          <Route path="delete-account" element={<AccountDeletion />} />
          <Route path="developer" element={<Developer />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </Suspense>
  );
};

export default App;
