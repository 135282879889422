import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type LangState = {
  lang: string;
};

const initialState: LangState = {
  lang: 'en',
};

const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    setLang(state, action: PayloadAction<string>) {
      state.lang = action.payload;
    },
  },
});

// const setLang = (lang: string) => async (dispatch: (action: any) => void) => {
//   dispatch(langSlice.actions.setLang(lang));
// };

export const langActions = {
  setLang: langSlice.actions.setLang,
};
export default langSlice.reducer;
