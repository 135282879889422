import React from 'react';
import classes from './About.module.scss';

// Images
import titleLinePng from 'assets/images/title-line.png';
import stayConnectedPng from 'assets/images/stay-connected.png';
import getNotifiedPng from 'assets/images/get-notified.png';
import stayUpdatedPng from 'assets/images/stay-updated.png';
import bannerPng from 'assets/images/banner.png';

export default function About() {
  return (
    <section id="about" className="section pb-0 about">
      <div className={classes['about-chat']}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="section-title">
                <h2>Why Gameout?</h2>
                <img src={titleLinePng} alt="title-line" className="img-fluid" />
              </div>
            </div>
            <div className={`col-md-12 ${classes['about-box']}`}>
              <div className="row mt-3">
                <div className={`col-lg-4 col-6 mt-3 ${classes['about-border']}`}>
                  <div className={classes['chat-box']}>
                    <img src={stayConnectedPng} alt="stay-connected" />
                    <h3 className="sub-title"> Stay Connected</h3>
                  </div>
                </div>
                <div className={`col-lg-4 col-6 mt-3 ${classes['about-border']}`}>
                  <div className={classes['chat-box']}>
                    <img src={getNotifiedPng} alt="get-notified" />
                    <h3 className="sub-title"> Get Notified </h3>
                  </div>
                </div>
                <div className="col-lg-4 mt-3">
                  <div className={classes['chat-box']}>
                    <img src={stayUpdatedPng} alt="stay-updated" />
                    <h3 className="sub-title">Stay Updated</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className={classes['chat-slide']}>
                <h3>Connect with your games and other players from all over the world</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid text-center">
        <img
          src={bannerPng}
          alt="banner.png"
          className={`img-fluid full-banner ${classes['full-banner']}`}
        />
      </div>
    </section>
  );
}
